<template>
    <div>
        <img src="./american-express.png" :width="size"/>
    </div>
</template>

<script>
// import iconBase from "../../../../common/components/iconBase.vue"
export default {
    components: { 
        // iconBase 
    },
    name: 'iconAmericanExpress',
    props: {
        size: {
            type: Number,
            default: 20
        }
    }
}
</script>
