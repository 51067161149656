<template>
    <div>
        <img src="./jcb-logo.png" :width="size+'px'"/>
    </div>
</template>
<script>
export default {
    name: 'iconJCB',
    props: {
        size: {
            type: Number,
            default: 20,
        }
    }
}
</script>