<template>
    <icon-base :width="size + 'px'" :fill="color" :viewBox="viewBox">
        <g>
            <path :class="[white ? 'white' : 'st59']" d="M249.5,164.1c-6.1,0-12.2,0-18.3,0c-0.4,0-0.4-0.2-0.4-0.4c-0.7-3.6-1.5-7.2-2.2-10.8   c-0.1-0.4-0.2-0.4-0.5-0.4c-8.3,0-16.7,0-25,0c-0.3,0-0.5,0.1-0.6,0.4c-1.3,3.6-2.6,7.2-3.9,10.9c-0.1,0.3-0.2,0.4-0.5,0.4   c-6.8,0-13.7,0-20.5,0c-0.1,0-0.2,0-0.3,0c0.2-0.6,0.4-1.1,0.7-1.6c9.8-23.3,19.5-46.6,29.3-69.9c0.9-2.2,2.3-4.1,4.5-5.3   c1.6-0.8,3.3-1.1,5.1-1.2c5.4,0,10.8,0,16.1,0c0.4,0,0.4,0.2,0.4,0.4c0.7,3.1,1.3,6.3,2,9.4c1.6,7.7,3.2,15.4,4.8,23.1   c1.7,8,3.3,16,5,24c1.4,6.9,2.9,13.7,4.3,20.6c0,0.1,0.1,0.1,0.1,0.2C249.5,163.9,249.5,164,249.5,164.1z M219,107.2   c-3.5,9.9-7.1,19.5-10.7,29.2c0.1,0,0.2,0,0.2,0c5.4,0,10.8,0,16.1,0c0.3,0,0.4-0.1,0.3-0.4c-1.7-8.4-3.5-16.9-5.2-25.3   C219.6,109.7,219.3,108.5,219,107.2z"/>
            <path :class="[white ? 'white' : 'st60']" d="M166.2,84.7c0.4,0.2,0.9,0.1,1.4,0.2c4.6,0.4,9.1,1.3,13.4,2.9c0.4,0.1,0.5,0.3,0.4,0.7   c-0.9,5.3-1.7,10.5-2.6,15.8c0,0.1-0.1,0.3-0.1,0.5c-0.3-0.2-0.7-0.3-1-0.4c-5.4-2.5-11-3.6-17-3.3c-2.3,0.1-4.6,0.5-6.7,1.5   c-1,0.5-1.8,1.1-2.5,1.9c-1.7,2-1.7,4.4,0,6.4c0.8,0.9,1.7,1.7,2.7,2.3c2.6,1.7,5.4,3.1,8.2,4.5c3.7,1.8,7.2,4,10.1,6.9   c3,2.9,5.1,6.4,5.7,10.5c0.6,3.5,0.3,6.9-0.7,10.2c-1.8,6.5-6,11.2-11.7,14.6c-4,2.3-8.4,3.7-12.9,4.5c-3.6,0.6-7.2,0.9-10.9,0.8   c-6.5-0.2-12.9-1.1-19.1-3.3c-0.6-0.2-1.2-0.5-1.8-0.7c-0.2-0.1-0.3-0.2-0.2-0.4c0.9-5.2,1.7-10.5,2.5-15.7   c0.1-0.5,0.1-0.9,0.2-1.4c0.2,0,0.4,0.1,0.6,0.2c3.1,1.5,6.3,2.9,9.7,3.8c4.4,1.2,8.9,1.5,13.5,1.2c2.2-0.2,4.4-0.8,6.4-1.9   c1.4-0.8,2.5-1.8,3.1-3.3c0.9-2.3,0.5-4.4-1.1-6.2c-1.4-1.6-3.3-2.7-5.1-3.8c-2.1-1.2-4.4-2.2-6.5-3.4c-3.5-1.9-6.8-4-9.5-6.9   c-2.7-2.8-4.6-6-5.2-9.8c-0.5-3.4-0.2-6.7,0.9-10c1.7-5.3,5.1-9.4,9.7-12.5c4.3-2.9,9.1-4.6,14.2-5.5c1.9-0.3,3.8-0.6,5.7-0.7   c0.1,0,0.2,0,0.2-0.1C162.3,84.7,164.3,84.7,166.2,84.7z"/>
            <path :class="[white ? 'white' : 'st59']" d="M49.3,128.1c0.9,3.7,1.5,7.4,2.3,11.1c0.2,0,0.2-0.2,0.2-0.4c4.3-11.4,8.5-22.9,12.8-34.3   c2.2-5.9,4.4-11.9,6.6-17.8c0.1-0.3,0.3-0.5,0.7-0.5c6.8,0,13.5,0,20.3,0c0.6,0,0.6,0,0.4,0.5c-10.4,25.6-20.9,51.3-31.3,76.9   c-0.2,0.4-0.3,0.5-0.7,0.5c-6.8,0-13.5,0-20.3,0c-0.4,0-0.6-0.1-0.7-0.5c-3-11.5-6.1-23.1-9.2-34.6c-2.8-10.7-5.7-21.4-8.5-32   c0-0.2-0.1-0.3-0.1-0.5c0.2,0,0.3,0.1,0.4,0.2c2.2,1.3,4.4,2.8,6.5,4.3c7.9,6,14.1,13.3,18.5,22.2   C47.9,124.7,48.6,126.4,49.3,128.1z"/>
            <path :class="[white ? 'white' : 'st59']" d="M98.2,164.1c-3.2,0-6.4,0-9.5,0c-0.4,0-0.5,0-0.4-0.5c1.2-7.2,2.3-14.4,3.5-21.6c1-6.1,2-12.2,3-18.3   c1.2-7.3,2.4-14.6,3.6-22c0.8-5.1,1.6-10.2,2.5-15.2c0-0.3,0.1-0.4,0.5-0.4c6.5,0,12.9,0,19.4,0c0.4,0,0.4,0.1,0.3,0.4   c-1.2,7.3-2.4,14.7-3.6,22c-1.1,6.9-2.3,13.8-3.4,20.8c-1.3,8.1-2.6,16.2-3.9,24.4c-0.5,3.4-1.1,6.7-1.6,10.1   c-0.1,0.4-0.3,0.4-0.5,0.4C104.7,164.1,101.5,164.1,98.2,164.1z"/>
            <path :class="[white ? 'white' : 'st59']" d="M21.7,96.3C21.7,96.3,21.8,96.3,21.7,96.3C21.7,96.4,21.7,96.4,21.7,96.3z"/>
        </g>
        <g>
            <polygon :class="[white ? 'white' : 'st69']" points="21.8,96.4 21.8,96.4 21.8,96.4  "/>
            <path :class="[white ? 'white' : 'st69']" d="M49.2,127.5c-0.8-4.2-1.6-8.4-2.5-12.5c-1.5-7.4-2.9-14.9-4.4-22.3c-0.7-3.2-2.6-5.4-5.9-6.2   c-1.2-0.3-2.4-0.4-3.6-0.4l0,0H0.7l-0.3,1.7c5.8,1.5,9.9,3,12.3,4c0.7,0.3,1.4,0.6,2,0.9c2.4,1.1,4.7,2.2,6.9,3.6   c0,0,0.1,0,0.1,0.1c0.2,0,0.3,0.1,0.4,0.2c2.2,1.3,4.4,2.8,6.5,4.3c7.9,6,14.1,13.3,18.5,22.2c0.8,1.6,1.5,3.3,2.2,5   C49.3,127.9,49.3,127.7,49.2,127.5z"/>
        </g>
    </icon-base>
</template>
<script>

export default {
name: 'iconVisa',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    white:{
        type: Boolean,
        default: false
    },
    viewBox:{
        type: String
    }
}
}
</script>

<style scoped>
	.st59{fill:#00579E;}
	.st60{fill:#00579F;}
    .st69{fill:#F9A51A;}
    .white{
        fill: #fff;
    }
</style>
