<template>
    <div>
        <img src="./troy-logo.png" alt="troy logo" srcset="" :width="size">
    </div>
</template>

<script>
export default {
    name: 'iconTroy',
    props: {
        size: {
            type: Number,
            default: 20,
        }
    }
}
</script>

<style scoped>

</style>